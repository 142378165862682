<template>
  <div>
    <b-carousel :indicator="false" :arrow="false" :pause-info="false">
      <b-carousel-item>
        <span class="image">
          <div class="image_text">
            <h2 class="title">
              <span>Our Labels</span>
            </h2>
          </div>
          <img src="/images/our-labels.jpg">
        </span>
      </b-carousel-item>
    </b-carousel>
    <section class="section has-text-centered">
      <h3 class="title is-3">
        Here at Cromon we love music so much we also own, run or have a vested interest in several Record Labels.
      </h3>
    </section>

    <div class="has-background-info">
      <div class="columns pt-3 pb-3">
        <div class="column has-text-centered">
          <div>
            <img src="/images/labels/absent-music.png" alt="Absent Music" width="400">
          </div>
        </div>
        <div class="column has-text-white">
          <div class="section">
            <h4 class="title is-4 has-text-white">
              Absent Music
            </h4>
            <p class="pb-4">
              Electronic Music since 2003.
            </p>
            <p class="pb-4">
              Absent Music was founded in 2003 on a sunny afternoon in the back of a pub in North London.
              The founder of Cromon brought life to the label with his own productions and a few friends dotted throughout it's sporadic release schedule.
            </p>
            <p class="pb-4">
              Having been a vehicle for Antikue releases for the best part of 20 years, 2022 has seen the Label have new life breathed into it, with new branding, and a fresh roster of releases lined up.
            </p>
            <p>
              <a href="https://absent-music.bandcamp.com" target="_blank" alt="Bandcamp">
                <b-icon
                  pack="fab"
                  icon="bandcamp"
                  type="is-white"
                  size="is-large"
                />
              </a>
              <a href="https://www.mixcloud.com/AbsentMusic/">
                <b-icon
                  pack="fab"
                  icon="mixcloud"
                  type="is-white"
                  size="is-large"
                />
              </a>
              <a href="https://twitter.com/MusicAbsent">
                <b-icon
                  pack="fab"
                  icon="twitter"
                  type="is-white"
                  size="is-large"
                />
              </a>
              <a href="https://tiktok.com/absentmusic">
                <b-icon
                  pack="fab"
                  icon="tiktok"
                  type="is-white"
                  size="is-large"
                />
              </a>
              <a href="https://www.instagram.com/absentmusiclabel">
                <b-icon
                  pack="fab"
                  icon="instagram"
                  type="is-white"
                  size="is-large"
                />
              </a>
              <a href="https://www.facebook.com/absentmusiclabel">
                <b-icon
                  pack="fab"
                  icon="facebook"
                  type="is-white"
                  size="is-large"
                />
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="content pt-3 pb-3"></div>

    <div class="has-background-success">
      <div class="columns pt-3 pb-3">
        <div class="column has-text-centered">
          <div>
            <img src="/images/labels/old-soul-records.png" alt="Old Soul Records" width="400">
          </div>
        </div>
        <div class="column has-text-white">
          <div class="section">
            <h4 class="title is-4 has-text-white">
              Old Soul Records
            </h4>
            <p class="pb-4">
              Classic Garage from Chris Mack
            </p>
            <p class="pb-4">
              Old Soul Records is a joint venture between Cromon and Chris McFarlane (Chris Mack, Potential Bad Boy), the spiritual home to Chris' late 90s garage output was relaunched to bring long out of print and unreleased Garage to the world.
            </p>
            <p>
              <a href="https://oldsoulrecords.bandcamp.com" target="_blank" alt="Bandcamp">
                <b-icon
                  pack="fab"
                  icon="bandcamp"
                  type="is-white"
                  size="is-large"
                />
              </a>
              <a href="https://www.facebook.com/oldsoulrecs/" target="_blank">
                <b-icon
                  pack="fab"
                  icon="facebook"
                  type="is-white"
                  size="is-large"
                />
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="content pt-3 pb-3"></div>

    <div class="has-background-link">
      <div class="columns pt-3 pb-3">
        <div class="column has-text-centered">
          <div>
            <img src="/images/labels/0g2n-logo1.png" alt="0G2N" width="350">
          </div>
        </div>
        <div class="column has-text-white">
          <div class="section">
            <h4 class="title is-4 has-text-white">
              0G2N
            </h4>
            <p class="pb-4">
              All are equal under the strobe.
            </p>
            <p class="pb-4">
              0G2N (No Guestlist Tonight) was born from the partnership that Cromon Founder Eugene Rasini and branding guru Jean-Robert Saintil, first sparked with meplus1.
            </p>
            <p class="pb-4">
              Aimed squarely at the dancefloor, 0G2N releases music to dance to, encompassing House, Deep House, Techno, Nu-Disco, and anything that makes your ass shake.
            </p>
            <p>
              <a href="https://meplus1.bandcamp.com/" target="_blank" alt="Bandcamp">
                <b-icon
                  pack="fab"
                  icon="bandcamp"
                  type="is-white"
                  size="is-large"
                />
              </a>
              <a href="https://www.mixcloud.com/meplus1/" target="_blank">
                <b-icon
                  pack="fab"
                  icon="mixcloud"
                  type="is-white"
                  size="is-large"
                />
              </a>
              <a href="https://twitter.com/meplus1music" target="_blank">
                <b-icon
                  pack="fab"
                  icon="twitter"
                  type="is-white"
                  size="is-large"
                />
              </a>
              <!-- <a href="https://tiktok.com/absentmusic">
                <b-icon
                  pack="fab"
                  icon="tiktok"
                  type="is-white"
                  size="is-large"
                />
              </a> -->
              <a href="https://www.instagram.com/0g2n_meplus1" target="_blank">
                <b-icon
                  pack="fab"
                  icon="instagram"
                  type="is-white"
                  size="is-large"
                />
              </a>
              <a href="https://www.facebook.com/NoGuestlistTonite" target="_blank">
                <b-icon
                  pack="fab"
                  icon="facebook"
                  type="is-white"
                  size="is-large"
                />
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="content pt-3 pb-3"></div>

    <div class="has-background-grey-light">
      <div class="columns pt-3 pb-3">
        <div class="column has-text-centered">
          <div>
            <img src="/images/labels/meplus1-logo.png" alt="0G2N" width="400">
          </div>
        </div>
        <div class="column has-text-white">
          <div class="section">
            <h4 class="title is-4 has-text-white">
              meplus1
            </h4>
            <p class="pb-4">
              meplus1 is a musical adventure between Cromon founder Eugene Rasini and Jean-Robert Saintil.
            </p>
            <p class="pb-4">
              The label is a home for left-of-center, quirky electronic dance music.
            </p>
            <p>
              <a href="https://meplus1.bandcamp.com/" target="_blank" alt="Bandcamp">
                <b-icon
                  pack="fab"
                  icon="bandcamp"
                  type="is-white"
                  size="is-large"
                />
              </a>
              <a href="https://www.mixcloud.com/meplus1/" target="_blank">
                <b-icon
                  pack="fab"
                  icon="mixcloud"
                  type="is-white"
                  size="is-large"
                />
              </a>
              <a href="https://twitter.com/meplus1music" target="_blank">
                <b-icon
                  pack="fab"
                  icon="twitter"
                  type="is-white"
                  size="is-large"
                />
              </a>
              <!-- <a href="https://tiktok.com/absentmusic">
                <b-icon
                  pack="fab"
                  icon="tiktok"
                  type="is-white"
                  size="is-large"
                />
              </a> -->
              <a href="https://www.instagram.com/0g2n_meplus1" target="_blank">
                <b-icon
                  pack="fab"
                  icon="instagram"
                  type="is-white"
                  size="is-large"
                />
              </a>
              <a href="https://www.facebook.com/meplus1music" target="_blank">
                <b-icon
                  pack="fab"
                  icon="facebook"
                  type="is-white"
                  size="is-large"
                />
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="content pt-3 pb-3"></div>

    <div class="has-background-primary">
      <div class="columns pt-3 pb-3">
        <div class="column has-text-centered">
          <div>
            <img src="/images/labels/slammin-vinyl-logo.jpg" alt="0G2N" width="350">
          </div>
        </div>
        <div class="column has-text-white">
          <div class="section">
            <h4 class="title is-4 has-text-white">
              Slammin Vinyl
            </h4>
            <p class="pb-4">
              Slammin Vinyl is an historic Hardcore label founded in the 90s by Mike Slammer &amp; DJ Red Alert
            </p>
            <p class="pb-4">
              Home to numerous anthems during it's tenure. Cromon aided the label in it's journey to digitize it's catalogue. Cromon Ltd is proud to have a 50% share in Slammin Vinyl's rights and it's historic recordings.
            </p>
            <p>
              <a href="https://meplus1.bandcamp.com/" target="_blank" alt="Bandcamp">
                <b-icon
                  pack="fab"
                  icon="bandcamp"
                  type="is-white"
                  size="is-large"
                />
              </a>
              <a href="https://www.mixcloud.com/meplus1/" target="_blank">
                <b-icon
                  pack="fab"
                  icon="mixcloud"
                  type="is-white"
                  size="is-large"
                />
              </a>
              <a href="https://twitter.com/meplus1music" target="_blank">
                <b-icon
                  pack="fab"
                  icon="twitter"
                  type="is-white"
                  size="is-large"
                />
              </a>
              <!-- <a href="https://tiktok.com/absentmusic">
                <b-icon
                  pack="fab"
                  icon="tiktok"
                  type="is-white"
                  size="is-large"
                />
              </a> -->
              <a href="https://www.instagram.com/0g2n_meplus1" target="_blank">
                <b-icon
                  pack="fab"
                  icon="instagram"
                  type="is-white"
                  size="is-large"
                />
              </a>
              <a href="https://www.facebook.com/meplus1music" target="_blank">
                <b-icon
                  pack="fab"
                  icon="facebook"
                  type="is-white"
                  size="is-large"
                />
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppOurLabels'
}
</script>
