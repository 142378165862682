<template>
  <footer class="footer">
    <div class="container">
      <div class="columns">
        <div class="column">
          <router-link tag="a" :to="{ path: '/' }">
            <img src="/images/cromon-logo.png" width="150">
          </router-link>
          <ul>
            <li><b>Cromon LTD</b> - <small>09778508</small></li>
          </ul>
        </div>
        <div class="column">
          <ul>
            <h4 class="title is-4">Services</h4>
            <li>
              <router-link :to="{name: 'AppDistribution'}">
                Distribution
              </router-link>
            </li>
            <li>
              <router-link :to="{name: 'AppRightsManagement'}">
                Rights Management
              </router-link>
            </li>
            <li>
              <router-link :to="{name: 'AppLabelManagement'}">
                Label Management
              </router-link>
            </li>
            <li>
              <router-link :to="{name: 'AppRoyaltyAccounting'}">
                Royalty Accounting
              </router-link>
            </li>
            <!-- <li>
              <router-link :to="{name: 'AppTech'}">
                Technology
              </router-link>
            </li> -->
          </ul>
        </div>
        <div class="column">
          <ul>
            <h4 class="title is-4">About Us</h4>
            <li>
              <router-link :to="{name: 'AppPartnerships'}">
                Partners
              </router-link>
            </li>
            <!-- <li>
              <router-link :to="{name: 'AppClients'}">
                Clients
              </router-link>
            </li> -->
            <!-- <li>
              <router-link :to="{name: 'AppCaseStudies'}">
                Case Studies
              </router-link>
            </li> -->
            <li>
              <router-link :to="{name: 'AppBranding'}">
                Branding
              </router-link>
            </li>
          </ul>
        </div>
        <div class="column">
          <ul>
            <h4 class="title is-4">Legal</h4>
            <li>
              <router-link :to="{name: 'AppTerms'}">
                Terms and Conditions
              </router-link>
            </li>
            <li>
              <router-link :to="{name: 'AppPrivacy'}">
                Privacy Policy
              </router-link>
            </li>
          </ul>
        </div>
        <!-- <div class="column">
          <ul>
            <h4 class="title is-4">Help</h4>
            <li>FAQs</li>
            <li>Support</li>
          </ul>
        </div> -->
      </div>
    </div>
    <hr>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>
