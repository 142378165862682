<template>
  <div class="has-background-black">
    <b-carousel :indicator="false" :arrow="false" :pause-info="false">
      <b-carousel-item v-model="slide">
        <span class="image">
          <div class="image_text">
            <h2 class="title">
              <span>{{ slide.title }}</span>
            </h2>
          </div>
          <img :src="slide.image">
        </span>
      </b-carousel-item>
    </b-carousel>
    <section class="section has-text-white">
      <h4 class="title is-4 has-text-white has-text-centered">
        Rights Collection & Management
      </h4>
      <div class="columns">
        <div class="column is-8 is-offset-2">
          <p>
            We can manage your Labels and Performers Rights worldwide with partnerships in place to collect in over 100 territories, maximising your revenue by having your Rights as widely registered as possible.
          </p>
          <br>
          <p>
            We will work with you and the societies to resolve any 3rd party disputes in as painless fashion as possible.
          </p>
          <br>
          <p>
            All royalties are processed of by our in-house specialists using our proprietary software.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AppRightsManagement',
  computed: {
    slide () {
      return this.$store.getters.slides.find(s => s.title === 'Rights Management')
    }
  }
}
</script>

<style lang="scss">
// .image {
//   // max-height: 500px;
// }
</style>