<template>
  <div>
    Funding
  </div>
</template>

<script>
export default {
  name: 'AppFunding'
}
</script>
