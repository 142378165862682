<template>
  <section class="section has-background-warning pt-5 pb-6">
    <h3 class="title is-3 has-text-white">Selected Label Partners</h3>
      <b-carousel-list
        :data="labels"
        :items-to-show="5"
        :repeat="true"
        icon-pack="fas"
      />
  </section>
</template>

<script>
export default {
  name: 'SelectedLabels',
  computed: {
    labels () {
      return this.$store.state.labels
    }
  }
}
</script>
