<template>
  <div>
    <b-carousel :indicator="false" :arrow="false" :pause-info="false">
      <b-carousel-item>
        <span class="image">
          <div class="image_text">
            <h2 class="title">
              <span>Privacy Policy</span>
            </h2>
          </div>
          <img src="/images/3E3232.png">
        </span>
      </b-carousel-item>
    </b-carousel>
    <div class="columns">
      <div class="column is-offset-2 is-two-thirds">
        <div class="section">
          <ol>
            <li>
              <b>Introduction</b>
              <ol>
                <li>
                  This policy applies where we act as a data controller with respect to users and their personal data of this website.
                </li>
                <li>
                  If we use cookies that are not strictly necessary for the running and use of this website we shall ask you for your consent upon first visit.
                </li>
              </ol>
            </li>
            <li>
              <b>Personal Data</b>
              <ol>
                <li>
                  Cromon LTD may process website usage data. This data may include your IP address, geographical location, browser type and version, operating system, refferal, length of visit, page views and website navigation paths, as well as data about the timing, frequency and pattern of your use. Cromon LTD may use third party services to source this data. Cromon LTD may process this data to better understand it and to improve it's website services.
                </li>
                <li>
                  Cromon LTD may process any data you provide via contacting us either from this website or third party sites. Cromon LTD may use this data to sell, market or offer services to you.
                </li>
                <li>
                  Cromon LTD may share your data with our Partners; current or future; if any enquiry involves Services for which we use said Partner to provide, we may also share data with Insurers and / or Professional Advisors insofar as being necessary for the purposes of managing risk, obtaining professional advice or any legal action.
                </li>
                <li>
                  Cromon LTD may process any of your personal data where necessary for the exercise or defence of legal claims, whether in court or in out-of-court proceedings if there is any infringement of copyright, image or data pertaining to Cromon LTD or it's clients.
                </li>
                <li>
                  Cromon LTD will never sell any data collected on you to any third party for any reasons.
                </li>
                <li>
                  Cromon LTD only accepts first party enquiries, please do not supply any other person or companies data to us, unless previously agreed upon by all parties involved.
                </li>
              </ol>
            </li>
            <li>
              <b>Amendments</b>
              <ol>
                <li>
                  We may choose to update this Privacy Policy at any time, please refer to the last updated date.
                </li>
                <li>
                  Be sure to check this page regularly to be aware of the latest revision.
                </li>
              </ol>
            </li>
            <li>
              <b>Company Details</b>
              <ol>
                <li>
                  This website is owned and run by Cromon LTD.
                </li>
                <li>
                  Cromon LTD is a registered company in the UK, our company registration number is 09778508.
                </li>
                <li>
                  Cromon LTD may use trading names for all or part of the services it provides.
                </li>
                <li>
                  You may contact us via the Contact form on this website.
                </li>
              </ol>
            </li>
            <li>
              <b>Change of Ownership or Business Transition</b>
              <ol>
                <li>In the event of a change of ownership or other business transition, such as a merger, acquisition or sale of our assets, your information may be transferred in accordance with applicable privacy laws.</li>
              </ol>
            </li>
          </ol>
          <br>
          <p>
            <small>
              Last updated 22/01/2020
            </small>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppPrivacy'
}
</script>
