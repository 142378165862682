<template>
  <div class="has-background-info">
    <b-carousel :indicator="false" :arrow="false" :pause-info="false">
      <b-carousel-item v-model="slide">
        <span class="image">
          <div class="image_text">
            <h2 class="title">
              <span>{{ slide.title }}</span>
            </h2>
          </div>
          <img :src="slide.image">
        </span>
      </b-carousel-item>
    </b-carousel>
    <section 
      class="section has-text-centered has-text-white"
      :class="study.background"
      v-for="(study, index) in case_studies"
      :key="index"
    >
      <div class="container">
        <article class="media">
          <figure class="media-left">
            <span class="image">
              <img :src="study.image">
            </span>
          </figure>
          <div class="media-content">
            <h2 class="title has-text-white">
              <span>{{ study.name }}</span>
            </h2>
            <div class="content">
              <div class="tags">
                <div class="tag" v-for="(tag, index) in study.tags" :key="index">{{ tag }}</div>
              </div>
            </div>
            <router-link 
              :to="{name: 'CaseStudy', params: { stub: study.stub, study: study }}"
              class="button is-primary is-small"
            >
              More Info
            </router-link>
          </div>
        </article>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AppCaseStudies',
  computed: {
    case_studies () {
      return this.$store.getters.case_studies
    },
    slide () {
      return this.$store.getters.slides.find(s => s.title === 'Case Studies')
    }
  }
}
</script>
