import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

Vue.use(Vuex)

// const PRINTFUL_APIKEY_ENC =  'a202NXUyZGktd3NpNC0zMnZnOnBoNm0tc3I0YXp1aXViZnU3' // btoa("km65u2di-wsi4-32vg:ph6m-sr4azuiubfu7")

const state = {
  // case_studies: [
  //   { index: 1,
  //     stub: 'track-rec',
  //     name: 'Taste Rec.',
  //     image: '/images/labels/taste-rec.jpg',
  //     background: 'has-background-danger',
  //     banner: '/images/labels/taste-banner.png',
  //     tags: ['Distribution', 'Fulfilment'],
  //     body: ""
  //   },
  //   {
  //     index: 2,
  //     stub: 'slammin-vinyl',
  //     name: "Slammin' Vinyl",
  //     image: '/images/labels/slammin-vinyl.jpg',
  //     background: 'has-background-info',
  //     banner: '/images/labels/slammin-banner.png',
  //     tags: ['Distribution', 'Rights Management'],
  //     body: ""
  //   }
  // ],
  labels: [
    { index: 2, name: "Slammin' Vinyl", image: '/images/labels/slammin-vinyl.png' },
    { index: 11, name: 'Old Soul Records', image: '/images/labels/old-soul-records.png' },
    { index: 3, name: 'So Forbidden', image: '/images/labels/so-forbidden.png' },
    { index: 12, name: 'VAA Extra Freedom', image: '/images/labels/vaa.png' },
    { index: 13, name: 'Goldseal Records', image: '/images/labels/goldseal-records.jpg' },
    { index: 1, name: 'Playgroup Recordings', image: '/images/labels/playgroup.png' },
    { index: 4, name: 'Track Records', image: '/images/labels/track-records.png' },
    { index: 7, name: 'Inna Mi Yard Productions', image: '/images/labels/inna-mi-yard-productions.png' },
    { index: 5, name: 'Traxploitation', image: '/images/labels/traxploitation.png' },
    { index: 9, name: 'Absent Music', image: '/images/labels/absent-music.png' },
    { index: 6, name: 'Third Eye Records', image: '/images/labels/third-eye-records.png' },
    { index: 8, name: 'Triple Geek', image: '/images/labels/triple-geek-recordings.png' },
    { index: 10, name: 'Vector Space Recordings', image: '/images/labels/vector-space-recordings.png' },
    { index: 14, name: 'Aurovine', image: '/images/labels/aurovine-logo.png' },
  ],
  merch: {
    products: []
  },
  retailers: [
    { index: 1, name: 'Amazon', image: '/images/dsps/amazon.png' },
    { index: 2, name: 'Spotify', image: '/images/dsps/spotify.png' },
    { index: 3, name: 'Deezer', image: '/images/dsps/deezer.png' },
    { index: 4, name: 'Beatport', image: '/images/dsps/beatport.png' },
    { index: 5, name: 'Juno', image: '/images/dsps/junodownload.png' },
    { index: 6, name: 'Apple Music', image: '/images/dsps/apple-music.png' },
    { index: 7, name: 'Youtube', image: '/images/dsps/youtube.png' },
    { index: 8, name: 'Audible', image: '/images/dsps/audible.png' },
    { index: 9, name: 'Facebook', image: '/images/dsps/facebook.png' },
    { index: 10, name: 'JOOX', image: '/images/dsps/joox.png' },
    { index: 11, name: 'Soundcloud', image: '/images/dsps/soundcloud.png' },
    { index: 12, name: 'TikTok', image: '/images/dsps/tiktok.png' },
    { index: 13, name: 'Anghami', image: '/images/dsps/anghami.png' }
  ],
  slides: [
    { index: 1, title: 'Distribution', link: '/distribution', image: '/images/distribution.png' },
    { index: 2, title: 'Rights Management', link: '/rights_management', image: '/images/rights.png' },
    { index: 3, title: 'Label Management', link: '/label_management', image: '/images/label-management.png' },
    { index: 4, title: 'Royalty Accounting', link: '/royalties', image: '/images/accounting.png' },
    { index: 5, title: 'Get in Touch', link: '/contact', image: '/images/contact.png' },
    // { index: 6, title: 'Merch', link: '/merch', image: '/images/merch.jpg' },
  ],
  tiles: [
    { index: 1, title: '1', link: '', image: '' },
    { index: 2, title: '2', link: '', image: '' },
    { index: 3, title: '3', link: '', image: '' },
    { index: 4, title: '4', link: '', image: '' },
  ]
}

const getters = {
  case_studies: state => {
    return state.case_studies
  },
  study: state => (stub) => {
    return state.case_studies.find(s => s.stub === stub)
  },
  labels: state => {
    return state.labels
  },
  slides: state => {
    return state.slides
  },
  tiles: state => {
    return state.tiles
  }
}

const mutations = {
  'LOAD_PRODUCTS' (state, payload) {
    state.merch.products = payload
  }
}

const actions = {
  // loadPrintfulProducts ({commit}) {
  //   axios.get('https://api.printful.com/store/products', {
  //     headers: {
  //       "Authorization": `Basic ${PRINTFUL_APIKEY_ENC}`
  //     }
  //   }).then((response) => {
  //     // commit('LOAD_PRODUCTS', response)
  //     console.log(response.data)
  //   })
  // }
}

const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions
})

export default store
