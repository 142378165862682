<template>
  <section class="section has-background-white pt-5 pb-5">
    <h3 class="title is-3 has-text-black">Selected Retail Partners</h3>
      <b-carousel-list
        :data="retailers"
        :items-to-show="5"
        :repeat="true"
        icon-pack="fas"
      />
  </section>
</template>

<script>
export default {
  name: 'RetailPartners',
  computed: {
    retailers () {
      return this.$store.state.retailers
    }
  }
}
</script>
