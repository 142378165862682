<template>
  <div class="has-background-info">
    <b-carousel :indicator="false" :arrow="false" :pause-info="false">
      <b-carousel-item v-model="study">
        <span class="image">
          <div class="image_text">
            <h2 class="title">
              <span>{{ study.name }}</span>
            </h2>
          </div>
          <img :src="study.banner">
        </span>
      </b-carousel-item>
    </b-carousel>
    <section 
      class="section has-text-centered has-text-white"
      :class="study.background"
    >
      <div class="container">
        <article class="media">
          <figure class="media-left">
            <span class="image">
              <img :src="study.image">
            </span>
          </figure>
          <div class="media-content">
            <h2 class="title has-text-white">
              <span>{{ study.name }}</span>
            </h2>
            <div class="content">
              <div class="tags">
                <div class="tag" v-for="(tag, index) in study.tags" :key="index">{{ tag }}</div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AppCaseStudy',
  computed: {
    study () {
      return this.$store.getters.study(this.$route.params.stub)
    }
  }
}
</script>
