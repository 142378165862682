import Vue from 'vue'
import Router from 'vue-router'

import AppHome from '@/pages/AppHome'
import AppArtistDistribution from '@/pages/AppArtistDistribution'
import AppBranding from '@/pages/AppBranding'
import AppCaseStudies from '@/pages/AppCaseStudies'
import AppCaseStudy from '@/pages/AppCaseStudy'
import AppClients from '@/pages/AppClients'
import AppContact from '@/pages/AppContact'
import AppDistribution from '@/pages/AppDistribution'
import AppFunding from '@/pages/AppFunding'
import AppLabelManagement from '@/pages/AppLabelManagement'
import AppMerchStore from '@/pages/AppMerchStore'
import AppOurLabels from '@/pages/AppOurLabels'
import AppPartnerships from '@/pages/AppPartnerships'
import AppPrivacy from '@/pages/AppPrivacy'
import AppPublishing from '@/pages/AppPublishing'
import AppRightsManagement from '@/pages/AppRightsManagement'
import AppRoyaltyAccounting from '@/pages/AppRoyaltyAccounting'
import AppTech from '@/pages/AppTech'
import AppTerms from '@/pages/AppTerms'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'AppHome',
    component: AppHome
  },
  {
    path: '/artist_distribution',
    name: 'AppArtistDistribution',
    component: AppArtistDistribution
  },
  {
    path: '/branding',
    name: 'AppBranding',
    component: AppBranding
  },
  {
    path: '/case_studies',
    name: 'AppCaseStudies',
    component: AppCaseStudies,
  },
  { 
    path: '/case_studies/:stub',
    name: 'AppCaseStudy',
    component: AppCaseStudy
  },
  {
    path: '/clients',
    name: 'AppClients',
    component: AppClients
  },
  {
    path: '/contact',
    name: 'AppContact',
    component: AppContact
  },
  {
    path: '/distribution',
    name: 'AppDistribution',
    component: AppDistribution
  },
  {
    path: '/funding',
    name: 'AppFunding',
    component: AppFunding
  },
  {
    path: '/label_management',
    name: 'AppLabelManagement',
    component: AppLabelManagement
  },
  {
    path: '/merch',
    name: 'AppMerchStore',
    component: AppMerchStore
  },
  {
    path: '/our_labels',
    name: 'AppOurLabels',
    component: AppOurLabels
  },
  {
    path: '/partnerships',
    name: 'AppPartnerships',
    component: AppPartnerships
  },
  {
    path: '/publishing',
    name: 'AppPublishing',
    component: AppPublishing
  },
  {
    path: '/privacy',
    name: 'AppPrivacy',
    component: AppPrivacy
  },
  {
    path: '/rights_management',
    name: 'AppRightsManagement',
    component: AppRightsManagement
  },
  {
    path: '/royalties',
    name: 'AppRoyaltyAccounting',
    component: AppRoyaltyAccounting
  },
  {
    path: '/tech',
    name: 'AppTech',
    component: AppTech
  },
  {
    path: '/terms',
    name: 'AppTerms',
    component: AppTerms
  }
]

const router = new Router({
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes,
  mode: 'history'
})

export default router