<template>
  <div class="has-background-success">
    <b-carousel :indicator="false" :arrow="false" :pause-info="false">
      <b-carousel-item v-model="slide">
        <span class="image">
          <div class="image_text">
            <h2 class="title">
              <span>{{ slide.title }}</span>
            </h2>
          </div>
          <img :src="slide.image">
        </span>
      </b-carousel-item>
    </b-carousel>
    <section class="section has-text-white has-text-centered">
      <div class="columns">
        <div class="column is-one-quarter is-offset-2">
          <div>
            <img src="/images/copyright-c.png" alt="Publishing">
            <h3 class="title is-3 has-text-white">Publishing Administration</h3>
            <p>
              Via administrator agreements and our own network we can collect Publishing royalties for your labels and artists.
            </p>
          </div>
        </div>
        <div class="column is-one-quarter is-offset-2">
          <div>
            <img src="/images/sync.png" alt="Synch">
            <h3 class="title is-3 has-text-white">Synchronisation</h3>
            <p>
              Strategic partnerships give us access to some of the leading music supervisors and music placement advisors for TV, Adverts and Online. 
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AppPublishing',
  computed: {
    slide () {
      return this.$store.getters.slides.find(s => s.title === 'Publishing')
    }
  }
}
</script>
