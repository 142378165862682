<template>
  <b-navbar fixed-top	 transparent>
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img src="/images/cromon-logo.png">
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-item tag="router-link" :to="{ path: '/distribution' }">
        <b>Distribution</b>
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/rights_management' }">
        <b>Rights Management</b>
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/label_management' }">
        <b>Label Management</b>
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/royalties' }">
        <b>Royalty Accounting</b>
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/our_labels' }">
        <b>Our Labels</b>
      </b-navbar-item>
      <!-- <b-navbar-item tag="router-link" :to="{ path: '/merch' }">
        <b>Merch Store</b>
      </b-navbar-item> -->
      <!-- <b-navbar-item tag="router-link" :to="{ path: '/funding' }">
        <b class="has-text-danger">Get Funded!</b>
      </b-navbar-item> -->
      <!-- <b-navbar-item tag="router-link" :to="{ path: '/tech' }">
        <b>Tech</b>
      </b-navbar-item> -->
      <!-- <b-navbar-item href="https://www.cromontor.com" target="_blank">
        <b>Mastering</b>
      </b-navbar-item> -->
    </template>
    <template slot="end">
      <b-navbar-item tag="router-link" :to="{ path: '/contact' }">
        <button class="button is-primary">Get In Touch</button>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
export default {
  name: 'AppNavbar',
}
</script>
