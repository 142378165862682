<template>
  <div class="has-background-danger">
    <b-carousel :indicator="false" :arrow="false" :pause-info="false">
      <b-carousel-item v-model="slide">
        <span class="image">
          <div class="image_text">
            <h2 class="title">
              <span>{{ slide.title }}</span>
            </h2>
          </div>
          <img :src="slide.image">
        </span>
      </b-carousel-item>
    </b-carousel>
    <section class="section has-text-white">
      <h4 class="title is-4 has-text-white has-text-centered">
        Label Management
      </h4>
      <div class="columns">
        <div class="column is-8 is-offset-2">
          <p>
            Using our state of the art Music Management software we can help run your label effeciently and easily, while you get on with the things you love.
          </p>
          <br>
          <p>
            The full Label Management package includes Distribution, Rights Management and Royalty accounting services tied together with our bespoke next gen software developed in-house by Label owners for Label owners.
          </p>
          <br>
          <p>
            Flexible deals to choose from for Labels small or large, active or catalogue.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AppLabelManagement',
  computed: {
    slide () {
      return this.$store.getters.slides.find(s => s.title === 'Label Management')
    }
  }
}
</script>
