<template>
  <div class="has-background-white">
    <b-carousel :indicator="false" :arrow="false" :pause-info="false">
      <b-carousel-item v-model="slide">
        <span class="image">
          <div class="image_text">
            <h2 class="title">
              <span>{{ slide.title }}</span>
            </h2>
          </div>
          <img :src="slide.image">
        </span>
      </b-carousel-item>
    </b-carousel>
    <section class="section">
      <h1 class="title is-2 has-text-centered">Get In Touch</h1>
      <h3 class="subtitle is-4 has-text-centered">Contact us to find out more about what we can do for you.</h3>
      <div class="columns is-centered">
        <div class="column is-two-thirds">
          <form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <div class="field">
              <input type="hidden" name="form-name" value="contact" />
            </div>
            <div class="field">
              <label for="contact.company" class="label">Company / Artist Name</label>
              <input name="contact.company" type="text" class="input" placeholder="Company Name" v-model="contact.company">
            </div>
            <div class="field">
              <label for="contact.enquiry_type" class="label">Enquiry Type</label>
              <div class="select is-fullwidth">
                <select name="contact.enquiry_type" v-model="contact.enquiry_type">
                  <option value="">Select Option...</option>
                  <option value="Distribution">Distribution</option>
                  <option value="Rights Management">Rights Management</option>
                  <option value="Label Management">Label Management</option>
                  <!-- <option value="Publishing">Publishing</option> -->
                  <!-- <option value="Web Development">Web Development</option> -->
                  <option value="General Enquiry">General Enquiry</option>
                  <option value="Partner Enquiry">Partner Enquiry</option>
                </select>
              </div>
            </div>
            <div class="field">
              <label for="contact.country" class="label">Country</label>
              <input name="contact.country" type="text" class="input" placeholder="Country" v-model="contact.country">
            </div>
            <div class="field">
              <label for="contact.company_type" class="label">Company Type</label>
              <div class="select is-fullwidth">
                <select name="contact.company_type" v-model="contact.company_type">
                  <option value="">Select Option...</option>
                  <option value="Independent Artist">Independent Artist</option>
                  <option value="Record Company">Record Company</option>
                  <option value="Label">Label</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            <div class="field">
              <label for="contact.name" class="label">Name</label>
              <input name="contact.name" type="text" class="input" placeholder="Name" v-model="contact.name">
            </div>
            <div class="field">
              <label for="contact.email" class="label">Email</label>
              <input name="contact.email" type="text" class="input" placeholder="Email" v-model="contact.email">
            </div>
            <div class="field">
              <label for="contact.phone" class="label">Phone</label>
              <input name="contact.phone" type="text" class="input" placeholder="Phone" v-model="contact.phone">
            </div>
            <div class="field">
              <label for="contact.website" class="label">Website</label>
              <input name="contact.website" type="url" class="input" placeholder="Website" v-model="contact.website">
            </div>
            <div class="field">
              <label for="contact.genres" class="label">Main Genre(s)</label>
              <input name="contact.genres" type="url" class="input" placeholder="Main Genres" v-model="contact.genres">
            </div>
            <div class="field">
              <label for="contact.catalogue_size" class="label">Catalogue Size (tracks / releases)</label>
              <input name="contact.catalogue_size" type="url" class="input" placeholder="Catalogue Size (tracks / releases)" v-model="contact.catalogue_size">
            </div>
            <div class="field">
              <label for="contact.releases_per_month" class="label">Releases per Month (average)</label>
              <input name="contact.releases_per_month" type="url" class="input" placeholder="Releases per Month (average)" v-model="contact.releases_per_month">
            </div>
            <div class="field">
              <label for="contact.artists" class="label">Well Known Artists?</label>
              <input name="contact.artists" type="url" class="input" placeholder="Well Known Artists?" v-model="contact.artists">
            </div>
            <div class="field">
              <label for="contact.referral" class="label">Where did you find Us?</label>
              <input name="contact.referral" type="url" class="input" placeholder="Where did you find Us?" v-model="contact.referral">
            </div>
            <div class="field">
              <label for="contact.more_info" class="label">Any Extra Info?</label>
              <textarea name="contact.more_info" class="textarea" v-model="contact.more_info" />
            </div>
            <div class="field">
              <div class="buttons">
                <button class="button is-success">Submit</button>
                <button class="button is-default has-text-danger">Reset</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AppContact',
  data () {
    return {
      contact: {
        company: '',
        enquiry_type: '',
        country: '',
        company_type: '',
        name: '',
        email: '',
        phone: '',
        website: '',
        genres: '',
        catalogue_size: '',
        releases_per_month: '',
        artists: '',
        referral: '',
        more_info: ''
      }
    }
  },
  computed: {
    slide () {
      return this.$store.getters.slides.find(s => s.title === 'Get in Touch')
    }
  }
}
</script>
