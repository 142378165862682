import { render, staticRenderFns } from "./AppMerchStore.vue?vue&type=template&id=78187318&"
import script from "./AppMerchStore.vue?vue&type=script&lang=js&"
export * from "./AppMerchStore.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports