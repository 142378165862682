<template>
  <div class="has-background-warning">
    <b-carousel :indicator="false" :arrow="false" :pause-info="false">
      <b-carousel-item v-model="slide">
        <span class="image">
          <div class="image_text">
            <h2 class="title">
              <span>{{ slide.title }}</span>
            </h2>
          </div>
          <img :src="slide.image">
        </span>
      </b-carousel-item>
    </b-carousel>
    <section class="section has-text-white has-text-centered">
      <div class="columns">
        <div class="column is-one-quarter is-offset-2">
          <div>
            <img src="/images/digital-distribution-128.png" alt="Digital Distribution">
            <h3 class="title is-3 has-text-white">Digital Distribution</h3>
            <div class="has-text-left">
              <p>
                Digital Distribution to all major and specialist stores via our strategic partnerships. Statements, Royalty accounting and Label Management using the mscmgmt.io next gen software.
              </p>
              <br>
              <p>
                NO hidden Costs<br>
                NO limits on Releases<br>
                NO monthly/yearly Fees<br>
                Simple and easy to assign splits make accounting easy and straightforward
              </p>
            </div>
          </div>
        </div>
        <div class="column is-one-quarter is-offset-2">
          <div>
            <img src="/images/bandcamp-button-bc-circle-black-128.png" alt="Bandcamp">
            <h3 class="title is-3 has-text-white">Bandcamp Fulfilment</h3>
            <div class="has-text-left">
              <p>
                Digital catalogue management, Physical product fulfilment, and all revenue collated with your other revenue streams for ease of accounting.
              </p>
              <br>
              <br>
              <p>
                Simple fulfillment charges for physical products<br>
                NO per upload Fees<br>
                Digital Releases uploaded<br>
                Accounting alongside all other revenue streams
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section has-text-white has-text-centered">
      <h4 class="title is-4 has-text-white">
        No matter the size of your catalogue or your experience in the business, we can help you exploit your catalogue to it's fullest potential.
      </h4>
      <p class="subtitle is-5 has-text-white">
        We are happy to work with Independent Artists as much as we enjoy working with new labels and large catalogue ingestions.
      </p>
    </section>
    <section class="has-text-centered">
      <retail-partners />
    </section>
    <section class="has-text-centered">
      <selected-labels />
    </section>
  </div>
</template>

<script>
import RetailPartners from '@/components/RetailPartners'
import SelectedLabels from '@/components/SelectedLabels'

export default {
  name: 'AppDistribution',
  components: {
    RetailPartners,
    SelectedLabels
  },
  computed: {
    slide () {
      return this.$store.state.slides.find(s => s.title === 'Distribution')
    }
  }
}
</script>
