<template>
  <div class="has-background-success">
    <b-carousel :indicator="false" :arrow="false" :pause-info="false">
      <b-carousel-item v-model="slide">
        <span class="image">
          <div class="image_text">
            <h2 class="title">
              <span>{{ slide.title }}</span>
            </h2>
          </div>
          <img :src="slide.image">
        </span>
      </b-carousel-item>
    </b-carousel>
    <div>
      <pre>
        {{ products }}
      </pre>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppMerchStore',
  computed: {
    slide () {
      return this.$store.getters.slides.find(s => s.title === 'Merch')
    },
    products () {
      return this.$store.state.merch.products
    }
  },
  created () {
    // this.$store.dispatch('loadPrintfulProducts')
  }
}
</script>
