<template>
  <div>
    <b-carousel :indicator="false" :arrow="false" :pause-info="false">
      <b-carousel-item>
        <span class="image">
          <div class="image_text">
            <h2 class="title">
              <span>Tech</span>
            </h2>
          </div>
          <img src="/images/3E3232.png">
        </span>
      </b-carousel-item>
    </b-carousel>
    <section class="section has-text-black">
      <h4 class="title is-4 has-text-black has-text-centered">
        Music Technology Services
      </h4>
      <div class="columns">
        <div class="column is-8 is-offset-2">
          <p>
            If you would like your own bespoke system setup we are here for you.
          </p>
          <br>
          <p>
            From Royalties to custom Management systems and everything inbetween (Licensing, Contracts, Rights etc..), our modular approach enables you to expand as you need.
          </p>
          <br>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AppTech'
}
</script>
