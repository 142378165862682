<template>
  <div>
    <b-carousel :indicator="false" :arrow="false" :pause-info="false">
      <b-carousel-item>
        <span class="image">
          <div class="image_text">
            <h2 class="title">
              <span>Clients</span>
            </h2>
          </div>
          <img src="/images/3E3232.png">
        </span>
      </b-carousel-item>
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: 'AppClients'
}
</script>
