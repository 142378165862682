<template>
  <div class="home">
    <b-carousel :autoplay=true :interval=100000 indicator-style="is-boxes" icon-pack="fas">
      <b-carousel-item v-for="(item, index) in slides" :key="index">
        <span class="image">
          <div class="image_text">
            <h2 class="title">
              <span>{{ item.title }}</span>
            </h2>
            <router-link
              :to="item.link"
              class="button is-link"
            >
              More Info
            </router-link>
            <!-- <a class="button is-link" :href="item.link">More Info</a> -->
          </div>
          <img :src="item.image">
        </span>
      </b-carousel-item>
    </b-carousel>
    <div class="section has-background-info">
      <div class="container">
        <h4 class="title is-2 has-text-white">Boutique Digital Music Services.</h4>
        <h5 class="title is-4 has-text-white">We help labels and independent artists find their feet in the brave new music world.</h5>
      </div>
    </div>
    <div class="section has-background-info">
      <div class="container">
        <p class="subtitle has-text-white">
          Through our hand picked partners and strategic alliances we can aid your digital presence and maximize your exposure.
        </p>
        <p class="subtitle has-text-white">
          Bespoke music technology allows you to have complete control over your data, and full management of your business at all times in one place.
        </p>
      </div>
    </div>
    <div class="has-background-white has-text-centered">
      <retail-partners />
    </div>
    <section class="has-text-centered">
      <selected-labels />
    </section>
  </div>
</template>

<script>
import RetailPartners from '@/components/RetailPartners'
import SelectedLabels from '@/components/SelectedLabels'

export default {
  name: 'AppHome',
  components: {
    RetailPartners,
    SelectedLabels
  },
  computed: {
    slides () {
      return this.$store.getters.slides
    },
    tiles () {
      return this.$store.getters.tiles
    }
  }
}
</script>

<style lang="scss">
.image {
  .title {
    padding: 0.5rem;
    background-color: whitesmoke;
  }
}

.image {
  .image_text {
    position: absolute;
    top: 15%;
    left: 8%;
  }
}

.image {
  max-height: 450px;
}
</style>
