<template>
  <div>
    <app-navbar />
    <section class="main">
      <router-view />
    </section>
    <app-footer />
  </div>
</template>

<script>
import AppFooter from './components/AppFooter.vue'
import AppNavbar from './components/AppNavbar.vue'

export default {
  name: 'App',
  components: {
    AppFooter,
    AppNavbar
  }
}
</script>
