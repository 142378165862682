<template>
  <div class="has-background-primary">
    <b-carousel :indicator="false" :arrow="false" :pause-info="false">
      <b-carousel-item>
        <span class="image">
          <div class="image_text">
            <h2 class="title">
              <span>Partners</span>
            </h2>
          </div>
          <img src="/images/partnerships.jpg">
        </span>
      </b-carousel-item>
    </b-carousel>
    <section class="section has-text-centered">
      <h4 class="subtitle is-4 has-text-white">
        We handle connections with Labels, Aggregators, Artist Management companies and Promo companies.
      </h4>
    </section>
    <section class="has-text-centered">
      <retail-partners />
    </section>
  </div>
</template>

<script>
import RetailPartners from '@/components/RetailPartners'

export default {
  name: 'AppPartnerships',
  components: {
    RetailPartners
  },
}
</script>
