<template>
  <div class="has-background-info">
    <b-carousel :indicator="false" :arrow="false" :pause-info="false">
      <b-carousel-item>
        <span class="image">
          <div class="image_text">
            <h2 class="title">
              <span>Royalty Accounting</span>
            </h2>
          </div>
          <img src="/images/accounting.png">
        </span>
      </b-carousel-item>
    </b-carousel>
    <section class="section has-text-white">
      <h4 class="title is-4 has-text-white has-text-centered">
        Royalty Accounting
      </h4>
      <div class="columns">
        <div class="column is-8 is-offset-2">
          <p>
            From Publishing to Sales revenues, we can help with your Royalty Accounting needs at all levels. Our experts are on hand to help answer any questions and guide you through the process of Accounting across multiple domains.
          </p>
        </div>
      </div>
    </section>
    <section class="section has-text-white has-text-centered">
      <div class="columns">
        <div class="column is-offset-2">

        </div>
      </div>
    </section>
  </div>
</template>

<script>
  export default {
    name: 'AppRoyaltyAccounting'
  }
</script>
