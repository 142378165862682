<template>
  <div>
    <b-carousel :indicator="false" :arrow="false" :pause-info="false">
      <b-carousel-item>
        <span class="image">
          <div class="image_text">
            <h2 class="title">
              <span>Terms &amp; Conditions</span>
            </h2>
          </div>
          <img src="/images/3E3232.png">
        </span>
      </b-carousel-item>
    </b-carousel>
    <div class="columns">
      <div class="column is-offset-2 is-two-thirds">
        <div class="section">
          <ol>
            <li>
              <b>Introduction</b>
              <ol>
                <li>
                  These Terms and Conditions shall govern your use of our website
                </li>
                <li>
                  By using this website, you are agreeing to accept these Terms and Condition in full and without prejudice. Should you disagree with any of these Terms and Conditions, you should <a href="https://duck.com" target="_blank">leave the website.</a>
                </li>
                <li>
                  Should you contact us via any of the methods available on this website certain information will be logged and bound by our Privacy Policy (as available on the Privacy Policy page).
                </li>
              </ol>
            </li>
            <li>
              <b>Copyright</b>
              <ol>
                <li>
                  The content of this website and the Cromon logo are &copy; 2020 Cromon LTD.
                </li>
                <li>
                  All other logo's and images are copyright their respective holders and are hereby used for illustrative purposes only. Some images may be Public Domain and are used in accordance with the Public Domain license.
                </li>
              </ol>
            </li>
            <li>
              <b>Permissions</b>
              <ol>
                <li>
                  By visiting this website, you are granted permission to view any and all pages in a web browser, subject to these Terms and Conditions.
                </li>
                <li>
                  Any assets as available on the Branding page are available for use per the Terms and Conditions accompanying them.
                </li>
                <li>
                  You may not duplicate, reproduce or edit any information on this website unless permitted via these Terms and Conditions or otherwise expressly permitted by Cromon LTD, if you wish to do so please contact Cromon LTD for permission.
                </li>
                <li>
                  We reserve the right to suspend, change or remove any content from this website at any time.
                </li>
              </ol>
            </li>
            <li>
              <b>Misuse</b>
              <ol>
                <li>
                  You must not use this website in any way that may cause performance, availablity or security issues with this website.
                </li>
                <li>
                  You must not use data collected from this website to solicit, contact or harrass any companies, entities or individuals.
                </li>
                <li>
                  Any information supplied to us via this website must be true and accurate.
                </li>
              </ol>
            </li>
            <li>
              <b>Warranties</b>
              <ol>
                <li>
                  We do not guarantee that this website is up to date, accurate or shall operate without failure or remain available at any certain moment in time.
                </li>
              </ol>
            </li>
            <li>
              <b>Service Agreements</b>
              <ol>
                <li>
                  All services provided by Cromon LTD and by this website or any connected website shall be governed by their own Terms and Conditions and Privacy Policies.
                </li>
                <li>
                  Any service Terms are available to clients; past, future or present; upon request via contacting Cromon LTD.
                </li>
                <li>
                  All services are provided on a first party basis and are not permitted to be resold, transferred or otherwise to any third party.
                </li>
              </ol>
            </li>
            <li>
              <b>Variation</b>
              <ol>
                <li>
                  We may revise or update these Terms and Conditions at any time.
                </li>
                <li>
                  The Terms and Conditions will be valid as they appear on the website or from the last updated date whichever is newest.
                </li>
                <li>
                  Your continued use of this website warrants that you accept these Terms and Conditions in their most recent form.
                </li>
              </ol>
            </li>
            <li>
              <b>Jurisdiction</b>
              <ol>
                <li>
                  These Terms and Conditions are goverened by and constrused in English Law.
                </li>
                <li>
                  Any dispute related to these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts of England.
                </li>
              </ol>
            </li>
            <li>
              <b>Company Details</b>
              <ol>
                <li>
                  This website is owned and run by Cromon LTD.
                </li>
                <li>
                  Cromon LTD is a registered company in the UK, our company registration number is 09778508.
                </li>
                <li>
                  Cromon LTD may use trading names for all or part of the services it provides.
                </li>
                <li>
                  You may contact us via the Contact form on this website.
                </li>
              </ol>
            </li>
          </ol>
          <br>
          <p>
            <small>
              Last updated 22/01/2020
            </small>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppTerms'
}
</script>
