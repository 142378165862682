<template>
  <div class="has-background-white">
    <b-carousel :indicator="false" :arrow="false" :pause-info="false">
      <b-carousel-item v-model="slide">
        <span class="image">
          <div class="image_text">
            <h2 class="title">
              <span>Branding</span>
            </h2>
          </div>
          <img src="/images/cromon-logo.png">
        </span>
      </b-carousel-item>
    </b-carousel>
    <section class="section">
      <div class="columns">
        <div class="column is-two-thirds is-offset-2">
          <div class="has-text-centered">
            <h3 class="title is-3">Official Branding Guidelines</h3>
          </div>
          <hr>
          <div class="content">
            <p>
              These guidelines are for Cromon Ltd licensees, authorized resellers, developers, customers, and other parties wishing to use Cromon Ltd's service marks or images in promotional,
              advertising, instructional, or reference materials, or on their web sites, products, labels, or packaging.
            </p>
            <p>
              Cromon Ltd's service marks, trade names, and trade dress are valuable assets.
              In following these guidelines, you help us protect our valuable trademark rights and strengthen our corporate and brand identities.
              By using a Cromon Ltd service mark or trade name, in whole or in part, you are acknowledging that Cromon Ltd is the sole owner of the service mark and promising that you
              will not interfere with Cromon Ltd's rights in the service mark, including challenging Cromon Ltd’s use, registration of, or application to register such trademark,
              alone or in combination with other words, anywhere in the world, and that you will not harm, misuse, or bring into disrepute any Cromon Ltd service mark.
              The goodwill derived from using any part of a Cromon Ltd trademark exclusively inures to the benefit of and belongs to Cromon Ltd.
              Except for the limited right to use as expressly permitted under these Guidelines, no other rights of any kind are granted hereunder, by implication or otherwise.
              If you have any questions regarding these guidelines, please talk to your Cromon Ltd representative or submit your query to Cromon Ltd’s legal department.
            </p>
            <h5 class="title is-5">Authorized Use of Cromon Ltd branding</h5>
            <br>
            <h6 class="subtitle is-6">1. Advertising, Promotional, and Sales Materials:</h6> Only Cromon Ltd and its authorized resellers and licensees may use the Cromon Ltd Logo in advertising,
            promotional, and sales materials.
            Such authorized parties may use the Cromon Ltd Logo only as specified in their agreement with Cromon Ltd and any associated Guidelines and such use must always be in
            conjunction with the appropriate terms that define the relationship authorized by their contract with Cromon Ltd
            <p>

            </p>
            <p>
              If you would like to use our branding across your sites and social media, grab the official files from <a href="/branding/cromon-branding.zip" class="has-text-danger">here</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AppBranding'
}
</script>
